














import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { ExpertiseStore, CreateExpertiseModel } from './store';
import PgaExpertiseCreate from './Components/PgaExpertiseCreate.vue';
import { CreateExpertiseResponse } from '@/services/iResourceService';

@Component({
	components:
	{
		PgaExpertiseCreate
	}
})
export default class ExpertisePageCreate extends mixins(SharedMixin)
{
	get ExpertiseCreate() : CreateExpertiseModel 
	{
		return ExpertiseStore.ExpertiseCreate;
	}

	mounted() : void
	{
		ExpertiseStore.InitExpertiseCreate();
	}

    async Create() : Promise<void>
    {
        try 
        {
			const response: CreateExpertiseResponse = await ExpertiseStore.CreateExpertise();
			const id: string = response.Id.toString();
			this.$router.push({ name: 'expertisedetails', params: { id }});
		}
        // eslint-disable-next-line no-empty
        catch(error) { }
	}

    undo() : void 
    {
		this.$router.go(-1);
	}

	beforeDestroy() : void
	{
		ExpertiseStore.DropExpertiseCreate();
		ExpertiseStore.ExpertiseCreate.Errors = new Map<string, Array<string>>();
	}
}
