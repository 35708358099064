
























































import { Component, Model } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { ExpertiseStore } from "../store";
import { EditExpertiseCommand, TechAreaModel, TechnologyTableModel } from "@/services/iResourceService";

@Component({})
export default class PgaExpertiseEdit extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: EditExpertiseCommand;

	get code(): number
	{
		return ExpertiseStore.ExpertiseEdit?.ViewModel?.Code ?? 0;
	}

	get description(): string
	{
		return ExpertiseStore.ExpertiseEdit?.ViewModel?.Description ?? "";
	}
	set description(desription: string)
	{
		ExpertiseStore.SET_EXPERTISE_EDIT_DESCRIPTION(desription);
	}

	get techAreas(): Array<TechAreaModel>
	{
		return ExpertiseStore.ExpertiseEdit?.ViewModel?.TechAreas ?? new Array<TechAreaModel>();
	}
	set techAreas(value: Array<TechAreaModel>)
	{
		ExpertiseStore.SET_EXPERTISE_EDIT_TECHAREAIDS(value.map(e => e.Id));
	}

	get technologies(): Array<TechnologyTableModel>
	{
		return ExpertiseStore.ExpertiseEdit?.ViewModel?.Technologies ?? new Array<TechnologyTableModel>();
	}
	set technologies(value: Array<TechnologyTableModel>)
	{
		ExpertiseStore.SET_EXPERTISE_EDIT_TECHNOLOGYIDS(value.map(c => c.Id));
	}

	get Errors(): Map<string, Array<string>>
	{
		return ExpertiseStore.ExpertiseEdit.Errors;
	}

	get techAreasDropDown(): Array<TechAreaModel>
	{
		return ExpertiseStore.TechAreasDropDown;
	}
	get technologiesDropDown(): Array<TechnologyTableModel>
	{
		return ExpertiseStore.TechnologiesDropDown;
	}

	async mounted(): Promise<void>
	{
		await ExpertiseStore.GetTechAreasDropDown();
		await ExpertiseStore.GetTechnologiesDropDown();
	}
}
