














import { Component } from "vue-property-decorator";
import { ExpertiseStore } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaExpertisesTable from './Components/PgaExpertisesTable.vue';
import { ExpertiseTableModel } from '@/services/iResourceService';

@Component({
    components:
    {
        PgaExpertisesTable
    }
})
export default class ExpertisePageIndex extends mixins(SharedMixin)
{
    private isLoading = true;

    get ExpertisesList() : Array<ExpertiseTableModel>
    {
        return ExpertiseStore.ExpertisesTable.Expertises;
    }
    
    async mounted() : Promise<void>
    {
        this.isLoading = true;
        await ExpertiseStore.GetAllExpertises();
        this.isLoading = false;
        
    }
}
