



















































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { ExpertiseStore } from "../store";
import { CreateExpertiseCommand, TechAreaModel, TechnologyTableModel } from "@/services/iResourceService";

@Component({ })
export default class PgaExpertiseCreate extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: CreateExpertiseCommand;
	
	get description(): string
	{
		return this.Model?.Description ?? "";
	}
	set description(desription: string)
	{
		ExpertiseStore.SET_EXPERTISE_CREATE_DESCRIPTION(desription);
	}

	get techAreaIds(): Array<TechAreaModel>
	{
		return new Array<TechAreaModel>();
	}
	set techAreaIds(techAreas: Array<TechAreaModel>)
	{
		ExpertiseStore.SET_EXPERTISE_CREATE_TECHAREAIDS(techAreas.map(ta => ta.Id));
	}

	get technologyIds(): Array<TechnologyTableModel>
	{
		return new Array<TechnologyTableModel>();
	}
	set technologyIds(technologies: Array<TechnologyTableModel>)
	{
		ExpertiseStore.SET_EXPERTISE_CREATE_TECHNOLOGYIDS(technologies.map(t => t.Id));
	}

	get Errors(): Map<string, Array<string>>
	{
		return ExpertiseStore.ExpertiseCreate.Errors;
	}

	get techAreasDropDown(): Array<TechAreaModel>
	{
		return ExpertiseStore.TechAreasDropDown;
	}
	get technologiesDropDown(): Array<TechnologyTableModel>
	{
		return ExpertiseStore.TechnologiesDropDown;
	}


	async mounted(): Promise<void>
	{
		await ExpertiseStore.GetTechAreasDropDown();
		await ExpertiseStore.GetTechnologiesDropDown();
	}
}
