





























import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { ExpertiseStore } from './store';
import { ExpertiseModel } from '@/services/iResourceService';

import PgaExpertiseDetails from './Components/PgaExpertiseDetails.vue';

@Component({
	components:
	{
		PgaExpertiseDetails
	}
})
export default class ExpertisePageDetails extends mixins(SharedMixin)
{
	private showDialog = false;
	private title = "";
	private message = "";

	get ExpertiseDetails() : ExpertiseModel 
    {
		return ExpertiseStore.ExpertiseDetails;
	}

	get IsHandleable() : boolean 
    {
		return ExpertiseStore.ExpertiseDetails.Handleable;
	}

	async mounted() : Promise<void>
	{
		ExpertiseStore.DropExpertiseDetails();
		const id: string = this.$route.params.id;
		await ExpertiseStore.GetExpertiseDetails(Guid.parse(id));
	}
	
	ShowDialogDelete(): void
	{
		this.title = "Deleting Expertise";
		this.message = `Are you sure you want to delete the Expertise '${this.ExpertiseDetails.Description}'?`;
		this.showDialog = true;
	}

	HideDialog(): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}
    
	Edit() : void
	{
		if (!this.ExpertiseDetails)
		{
			console.log("view model is null");
			return;
		}
		
		const id = this.ExpertiseDetails.Id.toString();
		this.$router.push({ name: 'expertiseedit', params: { id }});
	}

	SelectUsers() : void
	{
		if (!this.ExpertiseDetails)
		{
			console.log("view model is null");
			return;
		}
		const id = this.ExpertiseDetails.Id.toString();
		this.$router.push({ name: 'expertisesselectusers', params: { id }});
	}


	async Delete(): Promise<void>
	{
		try
		{
			if (this.ExpertiseDetails)
				await ExpertiseStore.DeleteExpertise(this.ExpertiseDetails.Id);
			this.$router.push({ path: '/expertises' });
		}
		finally
		{
			this.showDialog = false;
			this.title = "";
			this.message = "";
		}
	}
}
