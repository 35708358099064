














































































import { Component, Model } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { ExpertiseModel, Skill, TechAreaModel, TechnologyTableModel, UserSkillResource } from "@/services/iResourceService";

@Component({ })
export default class PgaExpertiseDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: ExpertiseModel;

	headers: Array<unknown> = 
	[
		{
			text: "Name",
			align: "start",
			sortable: true,
			value: "FullName",
		},
		{
			text: "Skill",
			align: "start",
			sortable: true,
			value: "Skill",
			width: "50%",
		}
	];

	get code(): number
	{
		return this.Model?.Code ?? 0;
	}
	get description(): string
	{
		return this.Model?.Description ?? "";
	}
	get techAreas(): Array<TechAreaModel>
	{
		return this.Model?.TechAreas ?? new Array<TechAreaModel>();
	}
	get technologies(): Array<TechnologyTableModel>
	{
		return this.Model?.Technologies ?? new Array<TechnologyTableModel>();
	}
	get modifiedDate(): string
	{ 
		const date: Date = new Date(this.Model?.ModifiedDate) ?? new Date();
		return date.toLocaleString();
	}
	get modifiedBy(): string
	{
		return this.Model?.ModifiedBy.FullName ?? "";
	}

	get users(): Array<UserSkillResource>
	{
		return this.Model?.Users ?? new Array<UserSkillResource>();
	}

	color(skill: Skill): string
	{
		switch (skill)
		{
			case Skill.Basic:
				return "error";
			case Skill.Intermediate:
				return "warning";
			case Skill.Advanced:
				return "success";
			default:
				return "";
		}
	}
}
