















import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from '@/feature/Shared/store';

import { ExpertiseStore, EditExpertiseModel } from './store';
import PgaExpertiseEdit from './Components/PgaExpertiseEdit.vue';

@Component({
	components:
	{
		PgaExpertiseEdit,		
	}
})
export default class ExpertisesPageEdit extends mixins(SharedMixin)
{
	get ExpertiseEdit() : EditExpertiseModel
	{
		return ExpertiseStore.ExpertiseEdit;
	}

	async Edit() : Promise<void>
	{
		try
		{
			await ExpertiseStore.EditExpertise();
			this.$router.go(-1);
		}
		catch(error) 
		{
			const errorMessage = error.get('id')?.[0] ?? "";
			SharedModule.SET_ALERT_ERROR_MESSAGE(errorMessage);
			SharedModule.SET_ALERT_IS_VISIBLE(errorMessage != "");
		}
	}

	undo() : void 
	{
		this.$router.push({ path: '/expertises'});
    }
    
	async mounted() : Promise<void>
	{
        const id: string = this.$route.params.id;
		await ExpertiseStore.GetExpertiseToEdit(Guid.parse(id));
	}
	
	beforeDestroy() : void
	{
		ExpertiseStore.ExpertiseEdit.Errors = new Map<string, Array<string>>();
	}
}
